import React, {Component} from "react"
import { HeaderAnim } from "../components/animscripts/page-header-anim";
// image imports
import Artslider  from "../components/projectslider"
import Layout from "../components/layout"
import Travelife from "../images/project-1.png"
// import Art from "../images/digital-art.jpg"
import CardMocukup from "../images/card-mockup.png";
import HexLogo from "../images/hex.png"
import ChatterMockup from "../images/chatter-mockup.png";

export default class Projects extends Component {
    componentDidMount(){
        HeaderAnim();
    }
    render(){
        return(
            <Layout>
                <div className="main__projects__container">
                <div className="cross__wrapper cross__wrapper__projects__top cross--shape"></div>
                <div className="page__header__container projects__header">
                    <h3 className="page__header__txt page__header__txt--top header--anim">Proj<span className="txt--stroke">ects</span></h3>
                </div>
                <section className="project__travelife project__container">
                    <div className="project__travelife__graphic">
                        <div className="project__travelife__graphic__circle grow--in"></div>
                        <img className="project__travelife__img in--from--below" src={Travelife} alt="Project Travelife mockup"></img>
                    </div>
                    <div className="project__travelife__info projects__info slide--in--horz--projects--txt">
                        <h4>1. Travelife</h4>
                        <p>Travelife is a imaginary travel 
                        company website. The prototyping
                        for this project was done in Adobe 
                        XD. It was developed using Html, 
                        vanilla JavaScript and SCSS. The 
                        website is fully responsive in all 
                        modern devices.</p>
                    </div>
                    <a className="project__travelife__link cursor-hover btn--hover--opacity" href="https://travelife.netlify.app/" target="_blank" rel="noreferrer">Visit Site →</a>
                </section>
                <section className="project__hex project__container">
                    <div className="project__hex__graphic">
                        <img className="project__hex__img in--from--below" src={HexLogo} alt="Project Hex logo"></img>
                    </div>
                    <div className="project__hex__info projects__info slide--in--horz--projects--txt">
                        <h4>2. Hex</h4>
                        <p>Hex is a voice assistant that helps you perform simple tasks like for eg. Get me the news from CNN.</p>
                    </div>
                    <a className="project__hex__link cursor-hover btn--hover--opacity" href="https://hex-assist.netlify.app/" target="_blank" rel="noreferrer">Visit Site →</a>
                </section>
                <section className="project__chatter project__container">
                    <div className="project__chatter__graphic">
                        <img className="project__chatter__img in--from--below" src={ChatterMockup} alt="Project chatter logo"></img>
                    </div>
                    <div className="project__chatter__info projects__info slide--in--horz--projects--txt">
                        <h4>3. Chatter</h4>
                        <p>A free open chat app built to communicate and connect with people.</p>
                    </div>
                    <a className="project__chatter__link cursor-hover btn--hover--opacity" href="https://chat-chatter.netlify.app/" target="_blank" rel="noreferrer">Visit Site →</a>
                </section>
                {/* <section className="project__art project__container">
                    <div className="project__art__slider__container">
                        <Artslider className="project__art__slider"/>
                    </div>
                    <div className="project__art__info projects__info">
                        <h4>4. Digital arts</h4>
                        <p className="project__art__info__txt">I have always enjoyed painting and drawing as long as I can remember. 
                            These are some of my digital paintings which I have just recently learned.</p>
            
                    </div>
                </section> */}
                <section className="project__card project__container project__card--5 project__container--5">
                    <img className="project__card__img project__card__img" src={CardMocukup} alt="Project business card front mockup"></img>
                    <div className="project__card__info projects__info">
                        <h4>4. Business Card Design</h4>
                        <p>An attractive and functional card design for you or your business.</p>
                    </div>
                </section>
            </div>
            </Layout>
        )
    }
}

// export default Porfolio
